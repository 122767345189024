/* You can add global styles to this file, and also import other style files */

@import "style-themes.scss";

@import "bootstrap/scss/bootstrap";

// Font Awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

// MDB SCSS
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";

html {
	scroll-behavior: smooth;
}

body {
	padding-top: 56px;
	font-family: Roboto;
	font-weight: 300;
	color: #212529;
}

.portal h1,h2,h3,h4 {
	font-weight: 300;
	font-family: Roboto;
	color: #212529;
}

.btn {
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
	padding: .84rem 2.14rem;
	margin: .375rem;
	font-size: .81rem;
	font-weight: 400;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	white-space: normal;
	text-transform: uppercase;
}

.btn.btn-link {
   box-shadow: none;
   font-weight: 400;
 }

.table td {
  padding: 0.75rem;
  font-size: 16px;
  font-weight: 300;
  font-family: Roboto;
  color: rgb(33, 37, 41);
}

.whitetext {
	color: #fff !important;
}

.main-header {
	width: auto;
}

.slider-label div label {
	margin-left: 12px;
	margin-top: auto;
	margin-bottom: auto;
}

.p-menubar-root-list {
	flex-wrap: nowrap !important;
}

.alignleft {
  text-align: left !important;
}

.aligncenter {
  text-align: center !important;
}

/*
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
*/